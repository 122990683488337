import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <div className='w-full max-w-none h-fit bg-v-green mt-20 pt-10 relative bottom-0 flex-col pb-5' id='footer' >
      <div className='flex justify-evenly w-full m-auto text-2xl'>
        <div className='text-white text-center w-1/4'>
          <p>Veranda Head Office</p>
          <div className='text-base mt-5'>
            #301 - 908 17th Avenue Southwest
            <br />
            Calgary, Alberta
            <br />
            Canada
          </div>
        </div>
        <div className='text-white text-center w-1/4'>
          <p>Sales Director</p>
          <div className='text-base mt-5'>
            Brooke Hawco
            <br />
            <div className=''>Brooke@verandalegacy.ca</div>
            <br />
            403-970-5433
          </div>
        </div>
        <div className='text-white text-center w-1/4'>
          <p>Contact</p>
          <div className='text-base mt-5'>
          info@verandalegacy.ca
          <br/>
          403-690-1342
          </div>
        </div>
      </div>
      <Link to={"/changePrice"}><div className='text-white flex justify-center w-fit m-auto mt-5'>Admin Control</div></Link>
    </div>
  )
}

export default Footer